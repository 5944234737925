@import '../../../../styles/customMediaQueries.css';

.wrapper {
  display: flex !important;
  width: 100%;
  justify-content: space-between !important;
}

container {
  padding: 64px 20px 0 20px;

  &:first-child {
    padding: 0 20px;
  }
}

.ctaButton {
  color: var(--colorGrey900) !important;
  border: 0.2px solid var(--colorGrey200) !important;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  background-color: var(--colorWhite) !important;
  @media (max-width: 500px) {
    margin: 0px !important;
    padding: 6px 10px !important;
    font-size: 12px !important;
  }
}

.ctaButton:hover {
  background-color: var(--colorGrey50) !important;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
}

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
  @media (max-width: 500px) {
    justify-items: center;
  }
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;
}

@media (--viewportMedium) {
  .twoColumns {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}

.threeColumns {
  composes: baseColumn;
}

@media (--viewportMedium) {
  .threeColumns {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}

.header {
  @media (max-width: 500px) {
    font-size: 25px;
  }
}

.fourColumns {
  composes: baseColumn;
}

@media (--viewportMedium) {
  .fourColumns {
    grid-template-columns: repeat(4, calc((100% - 4 * 32px) / 4));
  }
}

@media (max-width: 956px) {
  .fourColumns {
    grid-template-columns: repeat(2, calc((100% - 2 * 32px) / 2));
    justify-content: center;
  }
}

@media (max-width: 500px) {
  .fourColumns {
    grid-template-columns: repeat(2, calc((100% - 2 * 5px) / 2));
    justify-content: center;
  }
  .baseColumn {
    gap: 15px;
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
}

.loader {
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: space-between;
}

.loader::before,
.loader::after,
.loader div {
  content: '';
  width: 15px;
  height: 15px;
  background-color: var(--colorGrey900);
  border-radius: 50%;
  animation: bounce 0.6s infinite alternate;
}

.loader::before {
  animation-delay: 0.1s;
}

.loader::after {
  animation-delay: 0.3s;
}

@keyframes bounce {
  to {
    transform: translateY(15px);
  }
}

.newListingAction {
  background-color: var(--colorWhite);
  border: 1px solid var(--colorGrey200);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  padding: 10px 20px;
  border-radius: 24px;
}

.newListingAction:hover {
  background-color: var(--colorGrey50);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.NewListingButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.NewListingButton span .noListingWrapper {
  position: relative;
}
.containerNoListing {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding-top: 20px;
  opacity: 0.4;
  padding: 30px 20px 0 20px;
}
.card {
  width: 300px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.skeleton {
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0, #f0f0f0, #e0e0e0);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  animation: shimmer 1.5s infinite;
}
.image {
  height: 200px;
  width: 100%;
}
.content {
  padding: 16px;
}
.title {
  height: 24px;
  width: 80%;
  margin-bottom: 12px;
}
.description {
  height: 16px;
  width: 90%;
  margin-bottom: 8px;
}
.description:last-child {
  width: 60%;
}
@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

@media (max-width: 768px) {
  .card {
    width: calc(50% - 10px);
  }
}

@media (max-width: 580px) {
  .card {
    width: 100%;
  }
}
