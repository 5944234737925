@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.imageGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 10px;
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
}

@media (min-width: 930px) {
  .imageGrid {
    grid-template-columns: 1fr 580px;
    grid-template-rows: 500px;
  }
}

.leftColumn {
  height: 30rem;
  padding-right: 0;
}

@media (min-width: 930px) {
  .leftColumn {
    height: 500px;
    padding-right: 10px;
  }
}

.rightColumn {
  display: grid;
  grid-template-rows: auto;
  gap: 10px;
}

@media (min-width: 930px) {
  .rightColumn {
    min-width: 400px;
    height: 500px;
    grid-template-rows: 1fr 1fr;
  }
}

.topBlock {
  height: 180px;
}

@media (min-width: 930px) {
  .topBlock {
    height: 350px;
  }
}

.bottomBlock {
  height: 140px;
}

@media (min-width: 930px) {
  .bottomBlock {
    height: 100%;
  }
}
