@import '../../../../styles/customMediaQueries.css';

.wrapper {
  justify-content: center !important;
}

.notEnoughBlocks {
  @media (--viewportMedium) {
    display: none;
  }
}

.carouselContainer {
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  padding: 35px 20px 0 20px;
  height: 6rem;
  position: relative;
}

.carouselMain {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.carouselWrapper {
  display: flex;
  align-items: center;
  width: calc(240px * 12);
  animation: scroll 60s linear infinite;
  position: absolute;
  bottom: 0;
  left: 0;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-240px * 6));
  }
}
