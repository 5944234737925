.userContainer {
  padding: 12px 15px;
  /* background-color: #0063b2; */
  background-color: var(--colorGrey50);
  width: 100%;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

@media (max-width: 1022px) {
  .userContainer {
    display: none;
  }
}

.navBar {
  display: flex;
  max-width: var(--contentMaxWidthPages);
  justify-content: center;

  gap: 20px;
  margin: 0 auto;
  padding: 0 20px;
}

.navItem {
  font-size: 15px;
  font-weight: 500;
  color: var(--colorGrey900);
  padding: 10px 0;
  cursor: pointer;
  transition: color 0.2s;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  border-right: 1px solid #cbd5e0;
}

.navItem:is(:first-child) {
  border-left: 1px solid #cbd5e0;
}
