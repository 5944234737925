.root {
}

.wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 60rem;
  height: 33rem;
  z-index: 1;
  @media (max-width: 770px) {
    height: 45rem;
  }
}

.gridBlock3 a {
  width: 12rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.1px);
  -webkit-backdrop-filter: blur(2.1px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  @media (max-width: 1000px) {
    width: 14rem;
  }
  @media (max-width: 420px) {
    width: 11rem;
    font-size: 1rem;
    white-space: nowrap;
    padding: 0.5rem;
  }
}
.gridBlock1 a {
  width: 12rem;
  position: absolute;
  top: 35%;
  left: 50%;
  font-size: 1rem;
  transform: translate(-50%, -50%);
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.1px);
  -webkit-backdrop-filter: blur(2.1px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 0rem;
  padding-right: 0rem;
  @media (max-width: 1344px) {
    width: 14rem;
    top: 80%;
  }
  @media (max-width: 420px) {
    width: 11rem;
    font-size: 1rem;
    white-space: nowrap;
    padding: 0.5rem;
  }
}

.gridBlock1 a:hover {
  background: rgba(255, 255, 255, 0.3);
  transition-duration: 300ms;
}

.gridBlock3 a:hover {
  background: rgba(255, 255, 255, 0.3);
  transition-duration: 300ms;
}

.gridBlock3 .text {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.gridBlock2 .text {
  text-align: center;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.gridBlock3 .media {
  position: absolute;
  bottom: 0;
  right: -8rem;
  width: 25rem;
  height: 10rem;
  transition: transform 0.3s ease-out;

  @media (max-width: 1000px) {
    right: -150px;
  }
}
.MediaImage {
  position: absolute;
  bottom: -2rem;
  right: -4rem;
  width: 24rem;
  height: 20rem;
  rotate: -25deg;
  transition: transform 0.3s ease-out;

  @media (max-width: 1000px) {
    right: -150px;
  }
  @media (max-width: 1344px) {
    width: 15rem;
    height: 14rem;
  }

  @media (max-width: 930px) {
    width: 20rem;
    height: 18rem;
    right: -4rem;
  }
  @media (max-width: 450px) {
    opacity: 0.8;
    rotate: 20deg;
    right: -8rem;
    bottom: -4rem;
  }
}

.gridBlock1 .media {
  position: absolute;
  bottom: -5rem;
  left: -9rem;
  width: 29rem;
  height: 24rem;
  rotate: 25deg;
  transition: transform 0.3s ease-out;

  @media (max-width: 1000px) {
    right: -150px;
  }
  @media (max-width: 1344px) {
    width: 25rem;
    height: 18rem;
  }
  @media (max-width: 930px) {
    width: 28rem;
    height: 22rem;
    left: -8rem;
  }
  @media (max-width: 450px) {
    opacity: 0.5;
  }
}

.gridBlock2 .media {
  position: absolute;
  bottom: -1rem;
  left: 0;
  right: 0;
  margin-inline: auto;
  width: 100%;
  height: 25rem;
  opacity: 0.9;
  transition: transform 0.3s ease-out;
  z-index: 1;
}

.gridBlock1:hover .MediaImage {
  transform: rotate(10deg);
  transition: transform 0.3s ease-out;
}

.gridBlock1:hover .media {
  transform: scale(1.1);
  transition: transform 0.3s ease-out;
}
.gridBlock2:hover .media {
  transform: translateY(-0.2rem);

  transition: transform 0.3s ease-out;
}
.gridBlock3:hover .media {
  transform: scale(1.1);
  transition: transform 0.3s ease-out;
}

.media {
  height: 100%;
  width: 100%;
}

.wrapper {
  position: relative;
  height: 100%;
  cursor: pointer;
  border-radius: 1.2rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  overflow: hidden;
}

.wrapper .gridBlock3 {
  background-color: #ffc436;
}

.gridBlock2 {
  background-color: #009233;
}
.gridBlock1 .text {
  text-align: center;
}
.gridBlock1 {
  background-image: linear-gradient(to top, #002a4c, #002a4c, #0063b2);
}

.wrapper:hover {
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  transition-duration: 300ms;
}

.text {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  top: 0;
  z-index: 2;
  &:first-child {
    margin-top: 0;
  }
}

.text p,
.text h1,
.text h2,
.text h3,
.text h4,
.text h5,
.text h6 {
  color: white;
  @media (max-width: 450px) {
    font-size: 2.8rem;
  }
}
.listingContainer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 100%;
  width: 100%;
}

.cardWrapper {
  max-height: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  position: relative;
  height: 100%;
  cursor: pointer;
  width: 12rem;
  background-color: rgb(0 42 76 / 0.5);
  border-radius: 1rem /* 16px */;
  border-color: rgb(0 42 76 / 0.4);
  border-width: 1px;
  padding: 0.5rem;
  z-index: 2;
  @media (max-width: 645px) {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  @media (max-width: 1270px) {
    display: none;
  }
}

.cardContainerSecond {
  max-height: 22rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  position: relative;
  height: 100%;
  cursor: pointer;
  width: 15rem;
  background-color: rgb(0 42 76 / 0.5);
  border-radius: 1rem /* 16px */;
  border-color: rgb(0 42 76 / 0.4);
  border-width: 1px;
  padding: 1rem;
  z-index: 2;
}

.topGradientLine {
  position: absolute;
  top: -1px;
  left: 2.5rem;
  right: 2.75rem;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(48, 162, 255, 0),
    #30a2ff,
    rgba(56, 189, 248, 0)
  );
}

.bottomGradientLine {
  position: absolute;
  bottom: -1px;
  left: 2.75rem;
  right: 3.5rem;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(48, 162, 255, 0),
    #30a2ff,
    rgba(96, 165, 250, 0)
  );
}

.cardImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10rem;
  border-radius: 1rem;
}

.cardSecondImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 11rem;
  border-radius: 1rem;
}

.cardImageWrapper {
  display: inline-flex;
  width: 160px;
  height: 160px;
  position: relative;
}

.cardImageWrapperSecond {
  display: inline-flex;
  width: 180px;
  height: 180px;
  position: relative;
}

.cardImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.cardContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.cardHeader {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
}
.cardHeaderSecond {
  display: flex;
  align-items: center;
  width: 100%;
}

.cardHeaderLeft {
  display: flex;
  align-items: center;
  width: 100%;
  space-x: 0.5rem;
}

.logoContainer {
  position: relative;
  height: 2rem;
  width: 2rem;
  background-image: linear-gradient(to top left, #002a4c, #002a4c, #0063b2);
  border-radius: 9999px;
}

.logoImage {
  object-fit: contain;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 9999px;
}

.brandName {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: #e5e7eb;
  font-weight: bold;
}

.conditionBadge {
  background-color: #22c55e;
  border-radius: 1rem;
  padding-left: 0.9rem;
  padding-right: 0.5rem;
  width: 5rem;
  color: #e5e7eb;
}

.cardTitle {
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.titleText {
  color: #e5e7eb;
  font-weight: bold;
  letter-spacing: -0.025em;
}

.cardFooter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.priceText {
  color: #ffd20a;
  font-weight: bold;
  text-align: right;
  line-height: -0.25rem;
}

.addToCartButton {
  background-color: rgba(255, 255, 255, 0.2);
  width: 7rem;
  color: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.1px);
  transition-duration: 200ms;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  justify-content: center;
  font-size: 14px;
  padding: 0.5rem;
  @media (max-width: 645px) {
    font-size: 10px;
    padding: 0.2rem;
    width: 6rem;
  }
}

.addToCartButton:hover {
  box-shadow: 0 10px 15px -3px rgba(255, 210, 10, 0.6), 0 4px 6px -2px rgba(255, 210, 10, 0.6);
}

.listingCardPos {
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translate(0, -50%);
  z-index: 1;
}

.listingCardPosSecond {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  @media (max-width: 850px) {
    top: 60%;
  }
}
.listingCardPosLast {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translate(0, -50%);
  z-index: 1;
}
