.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    /* center */
    font-size: 0.9rem;
    margin-top: 0rem !important;
  }
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;

  & > ul,
  & > ol {
    text-align: left;
    width: fit-content;
    /* center */
    margin-left: auto;
    margin-right: auto;
  }

  & > p {
    /* center */
    margin-left: auto;
    margin-right: auto;
  }
}

.alignRight {
  text-align: right;
  & > ul,
  & > ol {
    text-align: left;
    width: fit-content;
    /* push to right */
    margin-left: auto;
  }

  & > p {
    /* push to right */
    margin-left: auto;
  }
}

/* .card {
  border-radius: var(--borderRadiusMedium);
  overflow: hidden;
  border: 1px solid #d3d3d3;
  transition: transform 0.3s ease-out;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
} */

.card {
  flex: 1 1 calc(33.33% - 1rem); /* Ensures all cards take equal width */
  display: flex; /* Flex for aligning content */
  flex-direction: column; /* Ensures content stacks vertically */
  align-items: stretch; /* Ensures cards are stretched equally */
  justify-content: space-between; /* Optional: Spaces out content inside */
  padding: 1rem;
  border: 1px solid #d3d3d3;
  border-radius: var(--borderRadiusMedium);
  cursor: pointer;
  position: relative;
}

@media (max-width: 500px) {
  .card {
    height: 14rem;
    justify-content: center;
  }
}

.card:hover {
  box-shadow: var(--boxShadowListingCard);
}

.title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.iconWrapper {
  border: 1px solid #d3d3d3;
  width: 2.5em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 10px;
}

.icon {
  width: 1.6rem;
  height: 1.6rem;
  color: #0063b2;
}

@media (max-width: 500px) {
  .iconWrapper {
    width: 3.5em;
    height: 3.5em;
    position: absolute;
    left: -0.5rem;
    top: -0.8rem;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
  .icon {
    width: 2.5rem;
    height: 2rem;
    color: #0063b2;
  }
}
