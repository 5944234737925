.root {
}

.media {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  width: 100%;
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }
}

.imageBlock {
  display: flex;
  align-items: center;
  width: 200px;
  height: 4rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  flex-shrink: 1;
}

.imageBlock img {
  width: 160px;
  object-fit: contain;
  padding: 0.5rem;
}
