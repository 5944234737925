.root {
}

.wrapper {
  position: relative;
  height: 100%;
  cursor: pointer;
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
  opacity: 1;
  filter: blur(0px);
}

.text {
  color: white;
  position: absolute;
  bottom: 1rem;
  left: 0;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0 1rem;
  opacity: 1;
  @media (max-width: 850px) {
    padding: 0 0.5rem;
  }
}

.text h1 {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  @media (max-width: 850px) {
    font-size: 1.5rem;
  }
  @media (max-width: 756px) {
    font-size: 3.5rem;
  }
}

.title {
  font-size: 2rem !important;
  color: white !important;
}

.overlay {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to bottom,
    rgba(0, 99, 178, 0) 0%,
    rgba(0, 99, 178, 0.2) 30%,
    rgba(0, 99, 178, 0.4) 100%
  );
  border-radius: 16px;
}

.wrapper:hover .overlay {
  background: linear-gradient(
    to bottom,
    rgba(0, 99, 178, 0.1) 0%,
    rgba(0, 99, 178, 0.4) 30%,
    rgba(0, 99, 178, 0.6) 100%
  );
  transition: background-color 0.5s ease;
}
.wrapper:hover .media {
  filter: blur(1px);
  transition: filter 0.3s ease-out;
}

.card:hover .text {
  opacity: 0;
}

.text p {
  background-color: white !important;
  border-radius: 24px;
  padding: 0.2rem;
  text-align: center;
  transition: transform 0.3s ease-out;
  @media (max-width: 850px) {
    padding: 0rem;
  }
  @media (max-width: 756px) {
    padding: 0.2rem;
    margin-top: 0rem;
  }
}
.text p a {
  font-size: 0.9rem;
  color: #0063b2;
  text-decoration: none;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-wrap: nowrap;

  @media (max-width: 890px) {
    font-size: 0.8rem;
  }

  @media (max-width: 850px) {
    font-size: 0.8rem;
  }

  @media (max-width: 756px) {
    font-size: 1rem;
  }
}

.wrapper:hover .text p {
  transform: translateY(-0.2rem);
  transition: transform 0.3s ease-out;
}
